import LeadScoring from '../../assets/Images/Sidebar/LeadScoringIcon.svg';
import OpportunityScoring from '../../assets/Images/Sidebar/OppurtunityScoring.svg';
import AccountScoring from '../../assets/Images/Sidebar/AccountScoringIcon.svg';
import HumanVsAI from '../../assets/Images/Sidebar/HumanAI.svg';
import AIInsights from '../../assets/Images/Sidebar/AIInsights.svg';
import LeadScoringWhite from '../../assets/Images/Sidebar/LeadScoringIconWhite.svg';
import OpportunityScoringWhite from '../../assets/Images/Sidebar/OppurtunityScoringWhite.svg';
import AccountScoringWhite from '../../assets/Images/Sidebar/AccountScoringIconWhite.svg';
import HumanVsAIWhite from '../../assets/Images/Sidebar/HumanAIWhite.svg';
import AIInsightsWhite from '../../assets/Images/Sidebar/AIInsightsWhite.svg';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import DataInsights from '../../assets/Images/Sidebar/DataInsights.svg';
import DataInsightsWhite from '../../assets/Images/Sidebar/DataInsightsWhite.svg';
import HistoricalInsights from '../../assets/Images/Sidebar/HistoricalInsights.svg';
import BudgetAllocation from '../../assets/Images/Sidebar/BudgetAllocation.svg';
import BudgetAllocationWhite from '../../assets/Images/Sidebar/BudgetAllocationWhite.svg';
import EmailVerification from '../../assets/Images/Sidebar/emailVerification.svg';
import emailVerificationDark from '../../assets/Images/Sidebar/emailVerificationDark.svg';
import fileAiDark from '../../assets/Images/Sidebar/file-aiDark.svg';
import fileAi from '../../assets/Images/Sidebar/file-ai.svg';


import HistoricalInsightsWhite from '../../assets/Images/Sidebar/HistoricalInsightsWhite.svg';

export const MMMItems = [
	{
		label: 'Historical Insights',
		icon: (
			<img
				src={HistoricalInsights}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={HistoricalInsightsWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		level: 0,
	},
	{
		label: 'Data Insights',
		icon: (
			<img
				src={DataInsights}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={DataInsightsWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		level: 1,
	},
	{
	    label: 'Budget Allocation',
		icon: (
			<img
				src={BudgetAllocation}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={BudgetAllocationWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		level: 2,
	},

];

export const predictiveScoringItems = [
	{
		label: 'Lead Scoring',
		icon: (
			<img
				src={LeadScoring}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={LeadScoringWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		onClick: 0,
	},
	{
		label: 'Opportunity Scoring',
		icon: (
			<img
				src={OpportunityScoring}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={OpportunityScoringWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		onClick: 1,
	},
	{
		label: 'Account Scoring',
		icon: (
			<img
				src={AccountScoring}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={AccountScoringWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		onClick: 2,
	},
	{
		label: 'Human vs AI',
		icon: (
			<img
				src={HumanVsAI}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={HumanVsAIWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		onClick: 3,
	},
	{
		label: 'AI Insights',
		icon: (
			<img
				src={AIInsights}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={AIInsightsWhite}
				alt='Data Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		onClick: 4,
	},
];
export const dataEnrichmentItems = 
[
	{
		label: 'AI Enrichment',
		icon: (
			<img
				src={fileAi}
				alt='AI Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={fileAiDark}
				alt='AI Enrichment'
				style={{ fontSize: 'inherit' }}
			/>
		),
		level: 0,
	},
	{
		label: 'Email Verifier',
		icon: (
			<img
				src={EmailVerification}
				alt='Email Verifier'
				style={{ fontSize: 'inherit' }}
			/>
		),
		iconSelected: (
			<img
				src={emailVerificationDark}
				alt='Email Verifier'
				style={{ fontSize: 'inherit' }}
			/>
		),
		level: 1,
	},
	
	
];

export const getInitialSelectedItem = (level: number) => {
	switch (level) {
		case 0:
			return 'Lead Scoring';
		case 1:
			return 'Opportunity Scoring';
		case 2:
			return 'Account Scoring';
		case 3:
			return 'Human vs AI';
		case 4:
			return 'AI Insights';
		default:
			return null;
	}
};

export const getInitialSelectedMMMItem = (level: number) => {
	switch (level) {
		case 0:
			return 'Historical Insights';
		case 1:
			return 'Data Insights';
		default:
			return null;
	}
};
export const getInitialSelectedDataEnrichmentItem = (level: number) => {
	switch (level) {
		case 0:
			return 'AI Enrichment';
		case 1:
			return 'Email Verifier';
		default:
			return null;
	}
};

export const DateRangePaths = [
	'/insights/chatbot',
	'/insights/predictive-scoring',
	'/insights/Media-Mix-Modelling',
	'/insights/Data-Enrichment',
];

export const secondSidebarPaths = [
	'/insights/chatbot', // Diggi-GPT
	'/insights/predictive-scoring', // Predictive Scoring
	'/insights/Media-Mix-Modelling', // MMM
	'/insights/Data-Enrichment',
	
];

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const drawerWidth = 80;
export const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width']),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width']),
	}),
}));
