import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import './Input.scss';

interface IInputLoaderProps {
	// Form Props
	control?: any;
	errors?: any;
	name: string;
	rows?: number;
	onChange?: Function;
	onkeypress?: any;
	onBlur?: any;
	onMouseOut?: any;
	onKeyPress?: any;
	onPaste?: any;
	sx?: any;
	id?: any;
	label?: string;
	placeholder?: string;
	inputRef?: any;
	refValue?: any;
	type?: any;
	value?: any;
	style?: any;
	disabled?: boolean;
	required?: boolean;
	variant?: any;
	helperText?: string;
	onInput?: any;
	defaultValue?: any;
	ignoreSpaces?: boolean;
	multiline?: boolean;
	onKeyDown?: any;
	outerDivStyle?: any;
	InputLabelProps?: any;
	InputProps?: any;
	className?: any;
	model?: any;
	error?: any;
	errorMessage?: any;
	autoFocus?: any;
	fieldMsg?:any
}
const useStyles = makeStyles((theme: any) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	input: {
		color:
			theme.palette.mode == 'dark'
				? 'rgba(255, 255, 255,0.3)'
				: 'rgba(0, 0, 0, 0.38)',
		borderRadius: '20px',
		opacity: theme.palette.mode == 'dark' ? 0.5 : 1,
	},
}));

const Input: React.FC<IInputLoaderProps> = (props) => {
	const classes = useStyles();

	const theme: any = useTheme();
	const onChangeHandler = (ev: any) => {
		if (!props.onChange) {
			return;
		}
		props.onChange(ev);
	};
	const onBlurHandler = (ev: any) => {
		if (!props.onBlur) {
			return;
		}
		props.onBlur(ev);
	};
	const onMouseOut = (ev: any) => {
		if (!props.onMouseOut) {
			return;
		}
		props.onMouseOut(ev);
	};
	const onKeyPressHandler = (ev: any) => {
		if (props.ignoreSpaces) {
			const theEvent: any = ev || window.event;
			if (ev.code === 'Space') {
				theEvent.returnValue = false;
				if (theEvent.preventDefault) theEvent.preventDefault();
			}
		}
		if (!props.onKeyPress) {
			return;
		}
		props.onKeyPress(ev);
	};
	const onPasteHandler = (ev: any) => {
		if (!props.onPaste) {
			return;
		}
		props.onPaste(ev);
	};
	const { control } = useForm();

	return (
		<div className='input' style={props.outerDivStyle}>
			<div className='input__box-container'>
				<Controller
					name={props.name}
					control={props.control || control}
					render={({ field: { onChange, value } }) => (
						<TextField
							multiline={props.multiline || false}
							error={props.error}
							id={props.id || null}
							name={props.name || 'textField_Name'}
							label={props.label || ''}
							placeholder={props.placeholder || ''}
							type={props.type || 'text'}
							disabled={props.disabled || false}
							required={props.required || false}
							inputRef={props.refValue || null}
							// InputLabelProps={props.InputLabelProps}
							size='small'
							InputLabelProps={{
								style:
									props.model && theme.palette.mode == 'dark'
										? { color: 'rgba(255, 255, 255,0.3)' }
										: {},
								...props.InputLabelProps,
								color: 'primary',
							}}
							rows={props.rows || 0}
							defaultValue={props.defaultValue || null}
							value={props.control ? value : props.value}
							InputProps={{
								...props.InputProps,
								style: { fill: 'red' },
								autoComplete: 'new-password',
								className: `${
									!props.model
										? props.className
											? props.className
											: 'input__defaultInput'
										: classes.input
								}`,
							}}
							onKeyDown={props.onKeyDown}
							onKeyPress={onKeyPressHandler}
							autoComplete={props.id ? props.id : null}
							helperText={props.helperText}
							style={{ ...props.style, borderRadius: '25px' }}
							fullWidth
							sx={
								props.sx
									? {
											'& .MuiInputBase-input.Mui-disabled': {
												WebkitTextFillColor: `${theme.palette.text.primary}`,
											},
									  }
									: {}
							}
							onInput={props.onInput || null}
							variant={props.variant || 'outlined'}
							onChange={props.control ? onChange : onChangeHandler}
							onBlur={onBlurHandler}
							onMouseOut={onMouseOut}
							autoFocus={props.autoFocus ? props.autoFocus : false}
							onPaste={onPasteHandler}
						/>
					)}
				/>
				{props.error ? (
					<FormHelperText
						style={{ marginLeft: '0.5rem', marginTop: '0rem', color: 'red' }}
					>
						{props.errorMessage ? props.errorMessage : 'Required'}
					</FormHelperText>
				) : null}
			    {props?.fieldMsg ? (
    <FormHelperText style={{ marginLeft: '15px', marginTop: '1px' }}>
        {props.fieldMsg || ""}
    </FormHelperText>
) : ""}


			</div>
		</div>
	);
};

export default Input;
